@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "PlayfairDisplay";
  src: url("./assets/fonts/PlayfairDisplay/static/PlayfairDisplay-Bold.ttf")
      format("truetype"),
    url("./assets/fonts/PlayfairDisplay/static/PlayfairDisplay-Regular.ttf")
      format("truetype");
  /* Add more src entries if your font comes in multiple file formats */
}
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("ttf"),
    url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("ttf");
  /* Add more src entries if your font comes in multiple file formats */
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("ttf"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("ttf");
  /* Add more src entries if your font comes in multiple file formats */
}
body {
  margin: 0;
  padding: 0;
}
.home .whoWeAre {
  background-image: url("./assets/images/whoweareHome.jpeg");
}
.secondDiv {
  background-image: url("./assets/images/Work\ Permit\ Consultancy\ About\ us.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.SignIn {
  height: 100%;
  margin: 0;
  background: url("./assets/images/green\ teal\ login.jpg");
  background-repeat: no-repeat;
  background-size: auto;
}
.LoginFirstDiv {
  background: url("./assets/images/green\ teal\ login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.cl-rootBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.nav-bar > img {
  display: none;
}
@media screen and (max-width: 539px) {
  .nav-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .nav-bar img {
    display: block;
  }
  .nav-bar .cl-rootBox {
    justify-content: flex-end;
  }
}
/* .cl-card {
  width: 75%;
} */
.cl-internal-b3fm6y {
  /* TODO Change Classname*/
  display: none;
}
.cl-signIn-start,
.cl-signUp-start {
  width: 75%;
}
@media screen and (min-width: 1319px) {
  .about-us-hero {
    padding-left: 4rem !important;
  }
}
@media screen and (min-width: 1516px) {
  .about-us-hero {
    padding-left: 7rem !important;
  }
}
/* New Requirements */
fieldset {
  margin-top: 20px;
  padding: 10px 12px;
  border-radius: 6px;
  border-width: 2px;
  align-items: center;
  border-color: #094b7291;
}
legend {
  color: 000;
  border-radius: 6px;
  padding: 5px;
  position: relative;
  font-weight: bold;
}
/* download icon for antecedent certificate  */
.download-icon {
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
}
.css-128fb87-MuiDataGrid-toolbarContainer {
  justify-content: space-between;
}
/* Hide scrollbar for all browsers */
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.columnHeader {
  background-color: #094b72;
  color: white;
}
.MuiDataGrid-columnHeader .MuiSvgIcon-root {
  color: white;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  font-weight: bold;
}
